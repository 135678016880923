// stylelint-disable declaration-no-important

//
// Border
//

.border {
  border: $border-width solid $border-color !important;
}
.border-top {
  border-top: $border-width solid $border-color !important;
}
.border-right {
  border-right: $border-width solid $border-color !important;
}
.border-bottom {
  border-bottom: $border-width solid $border-color !important;
}
.border-left {
  border-left: $border-width solid $border-color !important;
}

.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

@each $size, $value in $grid-sizes {
  @include media-breakpoint-up($size) {
    .border-#{$size} {
      border: $border-width solid $border-color !important;
    }
    .border-#{$size}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border-#{$size}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border-#{$size}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border-#{$size}-left {
      border-left: $border-width solid $border-color !important;
    }
    .border-#{$size}-0 {
      border: 0 !important;
    }
    .border-#{$size}-top-0 {
      border-top: 0 !important;
    }
    .border-#{$size}-right-0 {
      border-right: 0 !important;
    }
    .border-#{$size}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border-#{$size}-left-0 {
      border-left: 0 !important;
    }
  }
}

.border-white {
  border-color: $white !important;
}

//
// Border-radius
//

.rounded {
  border-radius: $border-radius !important;
}
.rounded-top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.rounded-right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.rounded-bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.rounded-left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
